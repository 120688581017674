import Button from "../widgets/Button";

interface ChurchAccountFormHeaderProps {
  isLoading: boolean;
  headerText: string;
  showSubmitBtn?: boolean;
}

const ChurchAccountFormHeader = ({
  isLoading,
  headerText,
  showSubmitBtn = true,
}: ChurchAccountFormHeaderProps) => {
  return (
    <div className="flex justify-between items-center">
      <h1 className="text-[36px] text-white">{headerText}</h1>
      {showSubmitBtn && (
        <Button
          isLoading={isLoading}
          className="w-[132px] h-[47px] mt-0"
          label="Save"
          type="submit"
        />
      )}
    </div>
  );
};

export default ChurchAccountFormHeader;
