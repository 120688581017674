const API_BASE = "/api";
const CHURCH_API_BASE = "/api/church";
const SUBSCRIPTION_BASE = "/api/subscription";
const API_CONSTANTS = {
  SIGNIN: `${API_BASE}/user/signin`,
  GET_ALL_CHURCH: `${CHURCH_API_BASE}/getAllChurch`,
  REGISTER_CHURCH: `${CHURCH_API_BASE}/register`,
  GET_CHURCH_DETAIL: (id: string) => {
    return `${CHURCH_API_BASE}/details/${id}`;
  },
  UPDATE_CHURCH: (id: string) => {
    return `${CHURCH_API_BASE}/update/${id}`;
  },
  SUBSCRIBE_PLAN: (priceId: string) => {
    return `${SUBSCRIPTION_BASE}/create-subscription?stripePriceId=${priceId}`;
  },
  CHECK_SUBSCRIPTION: `${SUBSCRIPTION_BASE}/check-subscription`,
  GET_PLAN_DETAILS: `${SUBSCRIPTION_BASE}/get-subscription-details`,
  EMAIL_CHECK: `${API_BASE}/user/check-email`,
  RESET_PASSWORD: `${API_BASE}/user/reset-password`,
  FORGOT_PASSWORD: `${API_BASE}/user/forgot-password`,
  CHURCH_ADMIN_SIGNUP: `${API_BASE}/user/signup`,
  UPDATE_PASSWORD: `${API_BASE}/user/update-password`,
  REQUEST_DEMO: `${API_BASE}/request-demo`,
};

export default API_CONSTANTS;
