import { ChangeEvent } from "react";
import {
  FieldValues,
  Path,
  RegisterOptions,
  UseFormReturn,
} from "react-hook-form";
import { cn } from "../../utils/common-utils";

interface InputFieldProps<T extends FieldValues> {
  className?: string;
  name: Path<T>;
  form: UseFormReturn<T, any, undefined>;
  type?: "text" | "email" | "password" | "number" | "url";
  label?: string;
  labelInfo?: string;
  validation?: RegisterOptions<T, Path<T>>;
  placeholder?: string;
  readonly?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const InputField = <T extends FieldValues>({
  className,
  name,
  type = "text",
  label,
  labelInfo,
  validation,
  placeholder,
  form,
  readonly,
  onChange,
}: InputFieldProps<T>) => {
  // Determine validation options, directly using validation object
  const validationOptions = validation
    ? {
        ...validation,
        required:
          typeof validation.required === "string"
            ? validation.required
            : validation.required === true
            ? "Required"
            : undefined,
      }
    : {};

  return (
    <div className={cn(className, "mb-4")}>
      {label && (
        <div className="flex space-x-1">
          <label htmlFor={name} className="block text-sm font-medium">
            {label}
          </label>
          {validation && validation.required && (
            <div className="text-red-500">*</div>
          )}
          {labelInfo && (
            <div className="text-xs text-gray-400">{`(${labelInfo})`}</div>
          )}
        </div>
      )}
      <input
        id={name}
        type={type}
        disabled={readonly}
        placeholder={placeholder}
        autoComplete="off"
        {...form.register(name, validationOptions)}
        onChange={(event) => {
          form.register(name, validationOptions).onChange(event);
          if (onChange) {
            onChange(event);
          }
        }}
        className={cn(
          "mt-1 block w-full px-3 py-3 border-none bg-[#FFFFFF0A] focus:bg-[#FFFFFF0A] rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm",
          readonly ? "bg-[#0D0F11]" : ""
        )}
      />
      {form.formState.errors[name] && (
        <p className="mt-2 text-sm text-red-600 text-start">
          {form.formState.errors[name]?.message?.toString()}
        </p>
      )}
    </div>
  );
};

export default InputField;
